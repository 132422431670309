body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 }
@-webkit-keyframes arrow-jump {
  0%   { opacity: 0;}
  100% { opacity: 1; 
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    }  
}

@keyframes arrow-jump {
  0%   { opacity: 0;}
  100% { opacity: 1; 
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    }  
}

html {
  scroll-behavior: smooth; 
}

h1, h2, h3, h4, h5, h5 {
  font-family: 'Lato', sans-serif;
  font-weight: bold !important;
}

p, a, label, span, li {
  font-family: "Lato" !important;
}

.sectionWithBackgroundImage {
  display: none;
  height: 825px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
} 

.sectionHeader {
  font-size: 48px;
  margin: auto;
  text-align: center;
  width: 98%;
}

.topHeader {
  padding-top: 120px;
}

.sectionSubheader {
  font-size: 28px;
  margin: auto;
  text-align: center;
  width: 95%;
}

.sectionCaption {
  font-size: 22px;
  width: 90%;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
}

.section {
  height: 850px;
}

.longSection {
  height: 1200px;
}

.gallerySection {
  height: 100%;
  padding-bottom: 25px;
}

.sectionText {
  font-size: 22px;
  width: 85%;
  margin: 10px auto;
}

.centerText {
  text-align: center;
}

.bodyText {
  margin: auto;
  width: 85%;
}

.largeBodyText {
  margin: auto;
  width: 85%;
  font-size: 17px;
}

.darkBlueBackground {
  background: #8090c2;
}

.tealBackground {
  background: #80b1c2;
}

.sandBackground {
  background: #c2b280;
}

.infoList {
  text-align: left;
  width: 85%;
  margin: auto;
  font-size: 18px;
}

.infoListItem {
  margin-bottom: 5px;
}

.marginTop {
  margin-top: 25px;
}

/* Background images for the slideshow. This is not the best way but I can make it work for now */
#northWall1Slide {
  display: block;
  background-image: url('/images/slideshow-images/northWall1S.jpeg');
}

#giantsMets1Slide {
  background-image: url('/images/slideshow-images/giantsMets1S.jpeg');
}

#giantsMets2Slide {
  background-image: url('/images/slideshow-images/giantsMets2S.jpeg');
}

#philliesEagles1Slide {
  background-image: url('/images/slideshow-images/philliesEagles1S.jpeg');
}

#yankeesChiefs1Slide {
  background-image: url('/images/slideshow-images/yankeesChiefs1S.jpeg');
}

#yankees1Slide {
  background-image: url('/images/slideshow-images/yankees2S.jpeg');
}

#chiefs1Slide {
  background-image: url('/images/slideshow-images/chiefs3S.jpeg');
}

#compassAnchor1Slide {
  background-image: url('/images/slideshow-images/compassAnchor1S.jpeg');
}

#chiefsEaglesCompass1Slide {
  background-image: url('/images/slideshow-images/chiefsEaglesCompass1S.jpeg');
}

#yankeesPhilliesAnchor1S {
  background-image: url('/images/slideshow-images/yankeesPhilliesAnchor1S.jpeg');
}

#wave1Slide {
  background-image: url('/images/slideshow-images/wave1S.jpeg');
}

